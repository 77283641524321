export function capitalizeWords(str) {
    try{
        return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    }
    catch(e){
        console.log('Error Capitalizing Words',e)
    }
}

export function camelCaseToCapitalized(str) {
  try {
      return str
          .replace(/([a-z0-9])([A-Z])/g, '$1 $2') // Insert space before uppercase letters
          .replace(/^./, match => match.toUpperCase()) // Capitalize the first letter of the string
          .replace(/ (.)/g, match => match.toUpperCase()) // Capitalize each word after a space
          .trim();
  } catch (e) {
      console.log('Error Converting camelCase to Capitalized', e);
  }
}


export const formatKey = (key) => {
  const keyMappings = {
      businessName: 'Business Name',
      orderNumber: 'Order Number',
      orderStatusURL: 'Order Status URL',
      productImageURL: 'Product Image URL',
      currency: 'Currency',
      total: 'Total',
      quantity: 'Quantity',
      orderStatus: 'Order Status',
      paymentStatus: 'Payment Status',
      datePaid: 'Date Paid',
      firstName: 'First Name',
      lastName: 'Last Name',
      customerName: 'Customer Name (First Name + Last Name)',
      phone: 'Phone',
      email: 'Email',
      phoneNumber: 'Phone Number',
      shippingAddress: 'Shipping Address',
      cartLink: 'Cart Link',
      subscriptionStatus: 'Subscription Status',
      fulfillmentStatus: 'Fulfillment Status',
      trackingNumber: 'Tracking Number',
      shippingProvider: 'Shipping Provider',
      trackingLink: 'Tracking Link',
      orderId: 'Order Id',
      reason: 'Reason',
      contactId: 'Customer Id',
      waId: 'Whatsapp Account Id',
      profileName: 'Whatsapp Profile Name',
      message: 'Message',
  };
  return keyMappings[key] || camelCaseToCapitalized(key);
};

function isDark(color) {
    const hex = color.replace('#', '');
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness < 128;
}


export function stringToLightColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = ((hash >> (i * 8)) & 0xff) % 150 + 100; // Restricting the range to lighter colors
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}


export function stringAvatar(name) {
    if (typeof name !== 'string' || name.trim() === '') {
      return {
        sx: {
          ml: -1,
          cursor: 'pointer',
          borderRadius: '50%',
          height: '40px',
          width: '40px',
          fontFamily: 'DM Sans Medium',
          fontSize: '14px',
          letterSpacing: 1,
          bgcolor: '#CCCCCC', 
          color: '#000000',  
        },
        children: '',
      };
    }
    const names = name.trim().split(' ');
    const initials = names.length > 1
      ? `${names[0][0].toUpperCase()}${names[1][0].toUpperCase()}`
      : names[0][0].toUpperCase();
    
    const backgroundColor = stringToLightColor(name); 
    const color = isDark(backgroundColor) ? '#FFFFFF' : '#000000'; 
    return {
      sx: {
        ml: -1,
        cursor: 'pointer',
        borderRadius: '50%',
        height: '40px',
        width: '40px',
        fontFamily: 'DM Sans Medium',
        fontSize: '14px',
        letterSpacing: 1,
        bgcolor: backgroundColor,
        color: color,
      },
      children: initials,
    };
}


export function uuidToBase64(uuid) {
    const cleanedUuid = uuid.replace(/-/g, '');
    const bytes = [];
    for (let i = 0; i < 16; i++) {
        bytes.push(parseInt(cleanedUuid.substr(i * 2, 2), 16));
    }
    const base64 = btoa(String.fromCharCode(...bytes));
    return base64;
}


export function truncateText(text, startWords = 5, endWords = 5) {
  if (!text) return '';

  const words = text.split(' ');

  if (words.length <= startWords + endWords) {
    return text; // No need to truncate if it's short enough
  }

  const start = words.slice(0, startWords).join(' ');
  const end = words.slice(-endWords).join(' ');

  return `${start} ... ${end}`;
}
