import { Box, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import PhotoRoundedIcon from '@mui/icons-material/PhotoRounded';
import VideocamRoundedIcon from '@mui/icons-material/VideocamRounded';
import AudiotrackRoundedIcon from '@mui/icons-material/AudiotrackRounded';
import FilePresentRoundedIcon from '@mui/icons-material/FilePresentRounded';
import MotionPhotosAutoSharpIcon from '@mui/icons-material/MotionPhotosAutoSharp';
import MediaModal from './MediaModal';
import { getMediaURL } from '../../services/conversations.js';

function MediaMessage({ event }) {
    const [open, setOpen] = useState(false);
    const [mediaURL, setMediaURL] = useState(null);

    const onMediaModalClose = () => {
        setOpen(false);
        setMediaURL(null);
    };

    const getIcon = (type) => {
        switch (type) {
            case 'image':
                return <PhotoRoundedIcon />;
            case 'sticker':
                return <MotionPhotosAutoSharpIcon />;
            case 'video':
                return <VideocamRoundedIcon />;
            case 'audio':
                return <AudiotrackRoundedIcon />;
            case 'document':
                return <FilePresentRoundedIcon />;
            default:
                return null;
        }
    };

    const handleMediaCardClick = async () => {
        try {
            const response = await getMediaURL(event);
            if (response.status === 200) {
                if (response.data?.mediaURL !== null) {
                    setMediaURL(response.data.mediaURL);
                    setOpen(true);
                }
            }
        } catch (error) {
            console.error('Error fetching media URL:', error);
        }
    };

    const isExpired = () => {
        const oneMonthAgo = new Date();
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
        
        //oneMonthAgo.setDate(oneMonthAgo.getDate() - 1); 
        // For testing One day 

        const eventDate = new Date(event.timestamp); 
        return eventDate < oneMonthAgo;
    };



    return (
        <React.Fragment>
            <Box
                sx={{
                    mt: 1,
                    width: '100%',
                    border: '1px solid #E3E3E3',
                    borderRadius: '10px',
                    p: 0.5,
                    minHeight: '50px',
                    display: 'flex',
                    flexDirection: 'column'
                }}
                onClick={!isExpired() ? handleMediaCardClick : null} // Disable click if expired
            >
                <Box
                    sx={{
                        borderRadius: '5px',
                        background: '#FFF6ED',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        flex: 1
                    }}
                >
                    {!isExpired() ? (
                        <React.Fragment>
                            <IconButton
                                size='large'
                                disableFocusRipple
                                disableRipple
                                disableTouchRipple
                                color='secondary'
                            >
                                {getIcon(event.type)}
                            </IconButton>
                            <Typography
                                sx={{
                                    fontFamily: 'DM Sans Medium',
                                    fontSize: '12px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: 'inline-block',  
                                    width: '100%'  
                                }}
                                >
                                {event[event.type]?.fileName}
                                </Typography>
                        </React.Fragment>
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent:'center',
                                alignItems: 'center',
                                flex:1
                            }}
                        >
                            <Typography sx={{ fontFamily: 'DM Sans Medium', fontSize: '14px' }}>Media Expired</Typography>
                        </Box>
                    )}
                   
                </Box>
                {event.type === 'audio' && event[event.type]?.voice === true && (
                    <Box
                        sx={{
                            p:0.5,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            flex: 1
                        }}
                    >
                        <Typography sx={{ fontFamily: 'DM Sans Medium', fontSize: '14px'}}>Voice Message</Typography>
                    </Box>
                )}
            </Box>
            <MediaModal
                open={open}
                onClose={onMediaModalClose}
                mediaType={event.type}
                mediaURL={mediaURL}
                setMediaURL={setMediaURL}
                mimeType={event[event.type]?.mimeType}
            />
        </React.Fragment>
    );
}

export default MediaMessage;
