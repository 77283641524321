import React from 'react'
import {Grid,Box, Typography,Avatar } from '@mui/material'
import ReactMarkdown from 'react-markdown';
import { formatDate } from '../../utils/dateTimeFunctions.js';
import TemplateMessage from './TemplateMessage.jsx';
import ShowMessageType from './ShowMessageType.jsx';
import MediaMessage from './MediaMessage.jsx';

const textFormattableEventTypes=['text','template']
const mediaFormatEventTypes=['image','sticker','video','audio','document']

function UserMessage({index,event}) {
    const imageHref = (src) => {
        window.open(src, '_blank');
    }
    const components = {
        img: ({ node, ...props }) => (
            <></>
        ),
        ol: ({ node, ...props }) => (
            <ol style={{ fontSize: '13px', maxWidth: '100%',padding:'10px',marginTop:'10px'}}> 
                {props.children}
            </ol>
        ),
        li: ({ node, ...props }) => (
            <li sx={{ fontSize: '13px', maxWidth: '100%'}}>
               {props.children}
            </li>
        ),
        a: ({ node, ...props }) => (
            <a href={props.href} onClick={(e)=>{e.preventDefault();imageHref(props.href)}} target="_blank" rel="noopener noreferrer">
                {props.children}
            </a>
        ),
        p:({ node, ...props }) => (
            <Typography sx={{ fontSize: '13px', whiteSpace: 'pre-wrap' }}>
                {props.children}
            </Typography>
        ),
    };
    return (
        <Grid key={index} item xs={12} sm={12} md={12} lg={12} xl={12}
            aria-label="userMessage"
            sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                mt:1.5,mb:1.5
            }}
        >
            <Box
                sx={{
                    width: 'fit-content',
                    maxWidth: '60%',
                    overflowWrap: 'break-word',
                    background:'#FFF6ED',
                    borderRadius:'20px 20px 0 20px',
                    p:1.5,
                    alignItems: 'center',
                }}
            >
                <Box sx={{display:'inline-flex',justifyContent:'flex-end',alignItems:'center',gap:1}}>
                    <Typography 
                        sx={{
                            fontFamily:'DM Sans Medium',
                            fontSize:'15px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                        }}
                    >You
                    </Typography>
                    <Typography 
                        sx={{
                            fontFamily:'DM Sans Light',
                            color:'#8B8D97',
                            fontSize:'12px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                        }}
                        >
                        {formatDate(event.timestamp)}
                    </Typography>
                </Box>
                
                <Box sx={{mt:0.5}}>
                    {textFormattableEventTypes.includes(event.type) && (
                        event.type ==='text' ? (
                            <React.Fragment>
                                <ReactMarkdown components={components}>{event.text}</ReactMarkdown>
                            </React.Fragment>
                        )
                    : typeof(event[event.type]) === 'object' && event.type ==='template' ? (
                        <React.Fragment>
                            <TemplateMessage event={event}/>
                        </React.Fragment>
                    ) 
                    : null
                    )}
                    {mediaFormatEventTypes.includes(event.type) && (
                        <React.Fragment>
                            <ShowMessageType event={event}/>
                            <MediaMessage event={event}/>
                        </React.Fragment>
                    )}

                </Box>
            </Box>
        </Grid>
  )
}

export default UserMessage