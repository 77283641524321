import React from 'react';
import { Card, CardContent, Typography, CardMedia, Box } from '@mui/material';

function MediaCard({ item,height }) {
  const renderMediaContent = () => {
    switch (item.subFormat) {
        case 'IMAGE':
            return (
                <img
                    src={item.url}
                    alt="Template Header Image"
                    style={{ width: '100%', height: '100%', objectFit: 'contain',borderRadius:'5px' }}
                />
            );
        case 'VIDEO':
            return (
                <video
                    controls
                    style={{ width: '100%', height: '100%', objectFit: 'contain',borderRadius:'5px' }}
                    title="Template Header Video"
                >
                    <source src={item.url} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            );
        case 'DOCUMENT':
            return (
                <iframe
                    src={item.url}
                    style={{ width: '100%', height: '100%', objectFit: 'contain',borderRadius:'5px' }}
                    title="Template Header Document"
                />
            );
        default:
            return null;
    }
  };

  return (
        <Box sx={{ width: '100%', height: height??'500px' }}>
            {renderMediaContent()}
        </Box>
  );
}

export default MediaCard;
