import { Box, Button, Grid, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import MediaCard from './MediaCard';
import ButtonStack from './ButtonsStack';


function TemplateMessage({ event }) {
    const order = ['HEADER', 'BODY', 'FOOTER', 'BUTTONS'];
    const sortedTemplate = event?.template?.sort(
        (a, b) => order.indexOf(a?.type) - order.indexOf(b?.type)
    )
    return (
        <React.Fragment>
        {sortedTemplate?.map((item, index) => {
            switch (item.type) {
                case 'HEADER':
                    return (
                        <Box key={index} sx={{mb:2}}>
                            {item.format==='TEXT' &&
                                <Typography sx={{ fontFamily:'DM Sans Medium',fontSize: '14px', whiteSpace: 'pre-wrap' }}>
                                    {item.text}
                                </Typography>
                            }
                            {item.format==='MEDIA' && ['IMAGE','VIDEO','DOCUMENT'].includes(item.subFormat) &&
                                <MediaCard item={item} height="500px"/>
                            }
                            {item.format==='MEDIA' && item.subFormat==='LOCATION' &&
                                <Typography sx={{ fontFamily:'DM Sans Medium',fontSize: '14px', whiteSpace: 'pre-wrap' }}>
                                    {(Array.isArray(item.location) ? item.location.join('\n') : item.location)}
                                </Typography>
                            }
                        </Box>
                    );
                case 'BODY':
                    return (
                        <Box key={index}>
                            <Typography sx={{ fontSize: '13px', whiteSpace: 'pre-wrap' }}>
                                {item.text}
                            </Typography>
                        </Box>
                    );
                case 'FOOTER':
                    return (
                        <Box key={index} sx={{mt:1,mb:1}}>
                            <Typography sx={{ fontFamily:'DM Sans Light',fontSize: '12px', whiteSpace: 'pre-wrap' }}>
                                {item.text || item.url}
                            </Typography>
                        </Box>
                    );
                case 'BUTTONS':
                    return <ButtonStack item={item}/>
                default:
                    return null;
            }
        })}
        </React.Fragment>
    );
}

export default TemplateMessage;
